.App {
  margin: auto;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

h1 {
  font-family: "Playfair Display", serif;

}

textarea {
  font-family: "Playfair Display", serif;

}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.message {
  max-width: 100%; /* Ensures it doesn't exceed the parent container */
  padding: 10px;
  margin: 5px auto; /* Centers the message and provides some spacing */
  border-radius: 20px;
  background-color: #e5e5ea; /* Example background color */
  font-family: "Inconsolata", monospace;
  display: inline-block;
  max-width: 200px;
}

.ul::-webkit-scrollbar  {
    display: none; /* Chrome, Safari, and Opera */
}

.messages::-webkit-scrollbar {
  display: none; /* Chrome, Safari, and Opera */
}

.message-sent {
  background-color: #e5e5ea; /* Blue bubble for sent messages */
}

.message-received {
  background-color: aqua; /* Grey bubble for received messages */
  color: grey;
}


/* @component: button */
.button {
  background-color: transparent;
  border: 1px solid var(--colors-primary--500);
  border-radius: 2px;
  cursor: pointer;
  color: var(--colors-primary--500);
  display: inline-block;
  font-family: var(--fonts-primary);
  letter-spacing: 0.045em;
  padding: 0.45rem;
  text-align: left;
  text-transform: uppercase;
}

.button:hover {
  background-color: var(--colors-primary--800);
  color: var(--colors-on_primary--500);
}

.button__icon {
  display: block;
  height: 1.125rem;
  line-height: 1;
  vertical-align: top;
  width: 1.125rem;
}

.button__icon {
  fill: currentcolor;
}

.button__content {
  display: inline-block;
  z-index: 2;
}

.button--size-lg,
.button--size-xl {
  border-width: 2px;
  padding: 0.55rem 0.7rem 0.7rem 0.55rem;
}



.button--size-xl {
  padding: 0.55rem 1.5rem 1.5rem 0.55rem;
}

.button--primary {
  color: var(--colors-secondary--500);
  border-color: var(--colors-secondary--500);
}

.button--primary:hover {
  background-color: paleturquoise;
  color: white
}

.button-toolbar {
  display: flex;
}

.button-toolbar .button {
  display: block;
  border-radius: 0;
}

.button-toolbar .button + .button {
  margin-left: -1px;
}

.flex-container {
  display: flex;
  align-items: center; /* This will vertically center align the items */
}

textarea {
  font-family: "Inconsolata", monospace;
  border: 1px solid aqua;
    height: 44px;
    outline: none;
}

.chat-bubble {
  border-radius: 16px;
  padding: 10px 20px;
  margin: 5px;
  display: inline-block;
  max-width: 60%;
}

.span-tabs {
  font-size: 20px;
  padding: 20px;
  color: grey;
  
  font-weight:100;
  letter-spacing: 5px;
  font-family: "Inconsolata", monospace;
}
p{
  font-family: "Inconsolata", monospace;
color: grey;
font-size: 20px;

}
span:hover {
  cursor: pointer;
  border-bottom: 1px solid aqua;
}

.messenger {
  position: fixed;
  bottom: 30px;

}

.messages {
  position: fixed;
  bottom: 100px;
  left: 50%;
  transform: translateX(-50%);
  max-width: 280px;
  max-height: 400px;
  overflow-y: auto;
  background-color: #fff;
  padding: 10px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center; /* This will center the messages */
}

.flex-container {
  display: flex;
  align-items: center; /* Aligns items vertically in the center */
  justify-content: space-between; /* Distributes space between the child elements */
}

.is-flex {
  cursor: pointer;
}

.loader {
  border: 12px solid #f3f3f3; /* Light grey */
  border-top: 12px solid lightgrey; /* Blue */
  border-radius: 50%;
  width: 1px;
  height: 1px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.sign-in:hover {
  color: white !important;
}

.network-logos {
  opacity: 0.7;
  filter: grayscale(100%);
  scale: 0.7;
}

.network-logos-mint {
  scale: 0.7;
}